<template>
  <div class="row">
    <div class="col-12 col-lg-8 c g">
      <div class="card">
        <div class="col-12 text-center" v-if="loading">
          <img
            src="../../assets/images/loading.svg"
            style="width: 60px; height: 60px"
            alt=""
          />
        </div>
        <div class="card-body">
          <h4 class="col-12 text-center">تعيين ايام كإجازة</h4>
          <div class="row" v-if="type == null">
            <div class="col-12 col-lg-6 g">
              <button
                class="btn btn-lg btn-success btn-block"
                @click="type = 'day'"
              >
                تعيين يوم مُحدد
              </button>
            </div>
            <div class="col-12 col-lg-6 g">
              <button
                class="btn btn-lg btn-primary btn-block"
                @click="type = 'fatra'"
              >
                تعيين فترة كإجازة
              </button>
            </div>
          </div>
          <!--  -->
          <div class="col-12 g" v-if="type == 'day'">
            <div class="form-group">
              <label for="">اختر اليوم</label>
              <input type="date" class="form-control" v-model="date" />
            </div>
          </div>
          <!--  -->
          <div class="col-12 g" v-if="type == 'fatra'">
            <div class="form-group">
              <label for="">الفترة من</label>
              <input type="date" class="form-control" v-model="from" />
            </div>
            <div class="form-group">
              <label for="">الفترة الى</label>
              <input type="date" class="form-control" v-model="to" />
            </div>
          </div>
          <!--  -->
          <div
            class="col-12 g"
            v-if="(from != null && to != null) || date != null"
          >
            <p>اختر المعلمين المراد تعيين الاجازة لهم</p>
            <div class="col-12 table-responsive">
              <table class="table table-hover table-bordered custom-table">
                <tbody>
                  <tr v-for="teacher in teachers" :key="teacher._id">
                    <th>
                      <div class="form-check" style="cursor: pointer">
                        <label class="form-check-label" style="cursor: pointer">
                          <input
                            style="cursor: pointer"
                            type="checkbox"
                            class="form-check-input"
                            v-model="selected_teachers"
                            :value="teacher.number"
                          />
                          {{ teacher.name }}
                        </label>
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center g">
              <button class="btn btn-relief-success" @click="addNow()">
                إضافة الاجازاة الآن
              </button>
            </div>
            <div class="col-12 text-center" v-if="loading">
              <img
                src="../../assets/images/loading.svg"
                style="width: 60px; height: 60px"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="card-footer" v-if="type == null">
          <div class="col-12 text-center" v-if="!viewfrees && type == null">
            <button class="btn btn-link" @click="viewfrees = true">
              عرض الاجازات المحفوظة
            </button>
          </div>
          <div class="col-12 table-responsive" v-if="viewfrees">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>المعلم</th>
                <th>التاريخ</th>
                <th>حذف</th>
              </thead>
              <tbody>
                <tr v-for="free in frees" :key="free._id">
                  <td>
                    {{ teacherName(free.number) }}
                  </td>
                  <td>{{ free.date }}</td>
                  <td>
                    <a
                      href="#"
                      @click="deleteFree(free._id)"
                      class="btn btn-link text-danger"
                    >
                      حذف
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      date: new Date().toISOString().toString().split("T")[0],
      teachers: [],
      selected_teachers: [],
      from: null,
      to: null,
      date: null,
      type: null,
      view: false,
      loading: true,
      frees: [],
      viewfrees: false,
    };
  },
  created() {
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/teachers/list", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.teachers = r.data.response;
          _g.loading = false;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    axios
      .post(localStorage.getItem("api") + "/reports/frees", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.frees = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    teacherName(number) {
      var name = null;
      this.teachers.forEach((element) => {
        if (element.number == number) {
          name = element.name;
        }
      });
      return name;
    },
    addNow() {
      var g = this,
        _g = this;
      this.loading = true;
      axios
        .post(localStorage.getItem("api") + "/reports/add-freedays", {
          jwt: localStorage.getItem("jwt"),
          type: this.type,
          date: this.date,
          from: this.from,
          to: this.to,
          teachers: this.selected_teachers,
        })
        .then(function (r) {
          if (r.data.status == 200) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ JWT",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          } else {
            g.selected_teachers = [];
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم الإضافة بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            _g.loading = false;
          }
        })
        .catch(function () {
          _g.loading = false;
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        });
    },
    deleteFree(id) {
      var g = this,
        _g = this;
      if (confirm("متأكد من الحذف؟")) {
        this.loading = true;
        axios
          .post(localStorage.getItem("api") + "/reports/delete-free", {
            jwt: localStorage.getItem("jwt"),
            id: id,
          })
          .then(function (r) {
            _g.loading = false;
            _g.frees = r.data.response;
          });
      }
    },
  },
};
</script>

<style>
</style>
